.page--body.exec {
  .page--title.exec {
    padding-left: 25px;
    padding-right: 25px;
  }

  p.page--description.exec {
    text-align: center;

    padding: 0 25%;
    margin: 0 0 2em;
  }

  #profile-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 30px;
    margin: 0 50px;
    justify-content: space-around;
    align-items: center;
  }
}

/* Updates Mobile Styling */
@media (max-width: 992px) {
  .page--body.exec {
    p.page--description.exec {
      padding: 0 10rem;
    }
  }
}

@media (max-width: 768px) {
  .page--body.exec {
    p.page--description.exec {
      padding: 0 3rem;
    }

    .page--title.exec {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

@media (max-width: 576px) {
  .page--body.exec {
    p.page--description.exec {
      padding: 0 1rem;
    }
  }
}