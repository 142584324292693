.page--body.advocate {
  padding: 0 0 30px;

  .page--title.advocate {
    padding-bottom: 10px;
  }

  h3.page-sub-title.advocate,
  h5 {
    font-family: 'Ubuntu', normal;
    font-weight: bold;
  }

  h5 {
    margin: 5px 0 0;
  }

  .page--description {
    margin: 0 auto;
  }

  p.advocate-text {
    text-align: justify;

    padding: 0 8rem;
    margin: 0 0 1.5rem;

    &.foot-note {
      text-align: center;

      padding: 0 4rem 30px;
      margin: 0;
    }
  }

  hr {
    text-align: center;
    width: 65%;
    margin: 2.25rem auto 2.5rem;
  }

  .advocate-table {
    border-collapse: collapse;
    margin: 10px 0 25px;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 350px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    :first-child {
      width: 110px;
    }

    thead tr {
      background-color: var(--nav-blue);
      color: #ffffff;
      text-align: left;
    }

    th,
    td {
      padding: 12px 15px;
    }

    tbody tr {
      border-bottom: 1px solid #dddddd;
    }

    th:first-child {
      text-align: right;
    }

    th:last-child {
      text-align: center;
    }

    tbody {
      td:first-child {
        text-align: right;
      }

      td:last-child {
        text-align: center;
      }

      tr:nth-of-type(even) {
        background-color: var(--outside-bg);
      }

      tr:last-of-type {
        border-bottom: 3px solid var(--nav-blue);
      }
    }
  }
}

.page--body.advocate .table-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  margin: 0 50px 0;
  justify-content: space-around;
  align-items: center;
}

// Styling for FAQ
.faq-container {

  .faq-row-wrapper {
    background-color: var(--inside-bg);

    width: 75%;
    margin: 0 auto;

    .faq-body {
      .faq-row {
        .row-title {
          font-family: 'Ubuntu', normal !important;
          font-size: 18px;
          font-weight: bold;
          font-style: italic;
        }

        .row-content {
          .row-content-text {
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .page--body.advocate {
    p.advocate-text {
      padding: 0 2.5rem !important;
      margin: 0 0 1rem;
    }
  }
}