// @import url('https://fonts.googleapis.com/css?family=Didact Gothic');
// @import url('https://fonts.googleapis.com/css?family=Ubuntu');

/* Global CSS Variables that will define our theme*/
:root {
  /* Variable for the regular body & outside body area */
  --outside-bg: #e7e7ea;
  --inside-bg: #F7F7FF;

  /* Variable colour used for the Navbar */
  --dark-bg: #32292F;
  --dark-bg-hover: #493f46;

  /* Variable colour used for the cards (ex. Council, Events) */
  --card-bg: #ababab12;

  /* Variable font colours to be used depending on the baground */
  --dark-bg-font: #767475;
  --dark-bg-hover-font: #a9a9a9;
  --white-bg-font: #32292F;
  --nav-bg-font: #F7F7FF;
  --btn-font: #F7F7FF;

  /* Blues */
  --zoo-blue: #00b1fc;
  --nav-blue: #3AAED8;
  --dark-blue: #0B3B6A;

  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

* {
  margin: 0;
  padding: 0;

  font-family: 'Didact Gothic';
  color: var(--white-bg-font);
}

html {
  /* force scrollbar, prevents initial gap */
  overflow-y: auto;
  overflow-x: auto;
  width: 90vw;
}

html,
body {
  height: 100vh;
  margin: auto;
  /* Centers content */
}

div#root {
  box-shadow: var(--box-shadow);
}

body {
  align-items: center;

  background: var(--outside-bg);

  /* min-width: 450px; */
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ubuntu', normal;
  text-align: center;
  word-wrap: break-word;
}

/* Styling used for the headers on every page */
.page--title {
  font-size: 35px;
  font-weight: bold;
  padding: 1.0em 0 5px;
}

.page--body {
  min-height: 100vh;
  background: var(--inside-bg);
}

/* Styling for all buttons */
.btn--dark {
  text-align: center;
  margin: 25px 0 0;

  .button {
    color: var(--btn-font);
    font-size: 18px;
    text-align: center;

    // border: .5px solid;
    border-radius: 25px;
    background-color: var(--dark-bg);

    padding: 10px 25px;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: var(--dark-bg-hover);
      box-shadow: var(--box-shadow);
    }

    /* Removes box that outlines object on click */
    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;
      outline-style: none;
      -moz-outline-style: none;
    }
  }
}

/* Mobile Formatting */
@media screen and (max-width: 576px) {
  html {
    /* force scrollbar, prevents initial gap */
    width: 100vw;
    margin: 0;
  }

  body {
    /* min-width: 100%; */
  }
}