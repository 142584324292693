/* Following sets up the executives bio cards */
.event-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background: #ababab12;
    border-radius: 25px;

    width: 85%;
    padding: 20px;
    margin: 0 9px 30px;

    // text-align: center;

    .row {
        justify-content: center;
        align-items: center;
    }

    h4 {
        font-size: 20px;
        font-weight: bolder;

        margin: 10px 0 5px;
    }

    p.title {
        margin: 0 0 5px;
    }

    .bio {
        padding: 9px 20px 0;
        font-size: 13px;
    }
}

.event-card.left {

    .description-right p,
    .description-right h4.event--title {
        text-align: right;
    }
}

.event-card.right {

    .description-left p,
    .description-left h4.event--title {
        text-align: left;
    }
}

@media (max-width: 768px) {
    .event-card {
        width: 95%;

        .row .image-right.col-lg-3.col-md-4.d-none.d-md-block,
        .row .image-left.col-lg-3.col-md-4.d-none.d-md-block {
            display: none !important;
        }
    }
}