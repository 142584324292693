/* Styling for page header -- Club's title */
h1.page-header {
  background-color: var(--nav-blue);
  color: var(--nav-bg-font);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.035rem;

  text-align: center;

  padding: 1.5rem;
  margin: 0;
}

@media (min-width: 576.1px) {

  /* Overwritting bootstrap styling for the navbar */
  nav.navbar {
    padding: 0 1.5rem;
    height: 50px;

    /* Remove the outline that appears on <a> <button> focus */
    :active,
    :focus {
      outline: none;
    }

    .navbar-brand {
      padding: 0;
    }

    /* Styling the font for the Navbar elements */
    #basic-navbar-nav .navbar-nav a,
    #basic-navbar-nav .navbar-nav .show.dropdown.nav-item {
      color: var(--nav-bg-font);

      &:hover {
        background-color: var(--dark-bg-hover);
        color: var(--nav-bg-font);
      }
    }
  }

  /* Overwritting bootstrap styling for the navbar dropdowns */
  nav.navbar #basic-navbar-nav .navbar-nav .dropdown .dropdown-menu {
    background-color: var(--dark-bg);
    color: var(--nav-bg-font);

    a:hover {
      background-color: var(--dark-bg-hover);
      border-radius: 5px;
    }
  }

  /* Following is styles for right-side of navbar */
  .navbar #basic-navbar-nav a.nav-link {
    color: var(--nav-default);

    font-size: 0.875rem;
    font-weight: 400;

    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);

    &.top-level {
      padding: 15px 0.625rem 0;
      text-align: center;

      height: 50px;
      width: 80px;
    }

    &.contact-us {
      width: 100px;
    }
  }

  /* Maintain highlight on NavDropown when dropdown menu has opened  */
  .navbar .show.dropdown.nav-item .dropdown-toggle.nav-link {
    color: var(--nav-hover) !important;
  }

  /* Overwritting style for the navbar's dropdown */
  .navbar #basic-navbar-nav .dropdown-menu {
    box-shadow:
      0 0.5rem 4rem rgba(0, 0, 0, 0.11),
      0 10px 20px rgba(0, 0, 0, 0.05),
      0 2px 3px rgba(0, 0, 0, 0.06);
    border-radius: 0.375rem;

    .dropdown-item {

      &:hover,
      &:active {
        background-color: var(--nav-dropdown-bg-hover);
        border-radius: 5px;
      }

      &:focus {
        background-color: var(--dark-bg);
        border-radius: 5px;
      }

      &.active {
        color: var(--nav-active);
        background-color: var(--nav-dropdown-bg-active);

        &:hover {
          background-color: var(--nav-dropdown-bg-active);
        }
      }

      a {
        color: var(--nav-dropdown-font);
        text-decoration: none;
      }
    }
  }

  .navbar #basic-navbar-nav .dropdown-menu {

    padding: 0;
    border: none;

    a.dropdown-item {
      color: var(--nav-dropdown-font);
      font-weight: 300;
      font-size: 0.9375rem;

      padding: 0.5rem 1.25rem
    }
  }

  .navbar #basic-navbar-nav a#basic-nav-dropdown.dropdown-toggle.nav-link {
    padding: 15px;
    text-align: center;

    height: 50px;
    width: 105px;
  }
}

.external-nav {
  &:focus {
    background-color: var(--dark-bg);

    &:hover {
      background-color: var(--dark-bg-hover);
      color: var(--nav-bg-font);
    }
  }
}