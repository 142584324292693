.page--body.commissioner {
  .page--title.commissioner {
    padding-left: 25px;
    padding-right: 25px;
  }

  p.page--description.commissioner {
    text-align: center;
    padding: 0 15%;
    margin: 0 0 1rem;
  }

  #profile-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 30px;
    margin: 45px 50px 0;
    justify-content: space-around;
    align-items: center;
  }

  /* The "Join Us Button formatting" */
  /* Following is style guide used on TO-TOP BUTTON */
  // .button-div {
  //   text-align: center;
  //   margin: 25px 0 0;

  //   .button {
  //     color: var(--btn-font);
  //     font-size: 18px;
  //     text-align: center;

  //     border: .5px solid;
  //     border-radius: 25px;
  //     background-color: var(--dark-bg);

  //     padding: 10px 25px;

  //     &:hover {
  //       cursor: pointer;
  //       text-decoration: none;
  //       background-color: var(--dark-bg-hover);
  //       box-shadow: var(--box-shadow);
  //     }

  //     /* Removes box that outlines object on click */
  //     &:active,
  //     &:focus {
  //       box-shadow: none;
  //       outline: 0;
  //       -moz-outline-style: none;
  //     }
  //   }
  // }
}

/* Updates Mobile Styling */
@media (max-width: 992px) {
  .page--body.commissioner {
    p.page--description.commissioner {
      padding: 0 5rem;
    }
  }
}

@media (max-width: 768px) {
  .page--body.commissioner {
    p.page--description.commissioner {
      padding: 0 3rem;
    }
  }
}

// @media (max-width: 576px) {
//   .page--body.commissioner {
//     p.page--description.commissioner {
//       padding: 0 3rem;
//     }
//   }
// }