.commissioners-card {
  text-align: center;

  border-radius: 25px;
  background: #ababab12;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  width: 260px;
  min-height: 130px;

  padding: 20px;
  margin: 0 9px 30px;

  .comm-role {
    font-size: 15px;
    font-family: Ubuntu;
    font-weight: bolder;
    margin: 0 0 4px;
  }

  .comm-description {
    font-size: 14px;
    margin: 0;
  }

  p.comm-names {
    font-size: 14.5px;
  }
}