/* 
File created to separate content specific to mobile/tablet formatting 
and to reduce the amount of content in Navbar.css
*/
nav.navbar.navbar-expand-sm.navbar-dark {
  /* Defining the background colour of the navbar*/
  background-color: var(--dark-bg) !important;

  .navbar-nav .dropdown .dropdown-toggle.nav-link {
    svg {
      margin-left: 0.35rem;
    }

    &::after {
      display: none;
    }
  }
}

@media (max-width: 576px) {

  .navbar-dark .navbar-nav .active>.nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show>.nav-link {
    color: var(--nav-bg-font);
  }

  nav.navbar.navbar-expand-sm.navbar-dark {
    padding: 5px 15px;

    .navbar-toggler {
      font-size: 1rem;
      border-radius: 50%;

      padding: 0.5rem;

      color: var(--nav-hover);

      &:hover {
        border-color: rgba(255, 255, 255, .25);
      }

      &:focus {
        outline: none;
      }
    }

    .navbar-nav {
      margin: 10px 0 10px 5px;
    }

    .dropdown-menu.show.dropdown-menu-right {
      background-color: var(--dark-bg);
      color: var(--nav-bg-font);

      .nav-link.lower-level.dropdown-item {
        &:hover {
          background-color: var(--dark-bg-hover);
        }

        &:active,
        .active {
          // color: var(--nav-bg-font);
          background-color: var(--dark-bg);

          &:hover {
            background-color: var(--dark-bg-hover);
          }
        }
      }
    }

    .nav-link.top-level,
    .nav-link.top-level.active,
    #basic-nav-dropdown.dropdown-toggle.nav-link,
    .nav-link.lower-level.--active-item.dropdown-item {
      color: rgba(255, 255, 255, .5);

      svg {
        fill: rgba(255, 255, 255, .5) !important;
      }

      &:hover {
        color: var(--nav-bg-font);
        background-color: var(--dark-bg-hover);

        svg {
          fill: var(--nav-bg-font) !important;
        }
      }
    }

    .nav-link.lower-level.--active-item.active.dropdown-item {
      color: var(--nav-bg-font);
      background-color: var(--dark-bg);

      &:hover {
        background-color: var(--dark-bg-hover);
      }
    }
  }
}