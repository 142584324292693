.footer--body {
    background-color: var(--dark-bg);
    color: var(--dark-bg-font);
    transition: transform 350ms cubic-bezier(0.5, 0.63, 0.18, 0.98);

    padding: 50px 50px 20px;


    hr {
        width: 80%;
        margin: 35px auto 25px;
        border: 0.5px solid var(--dark-bg-font);
    }


    a,
    p,
    h4 {
        color: var(--dark-bg-font);
    }

    h4 {
        font-size: 19px;
        text-align: left;
    }


    a:hover {
        color: var(--dark-bg-hover-font);
    }

    a:focus,
    a:hover {
        outline: none;
        text-decoration: none;
    }

    .footer--upper-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        width: 80%;
        margin: 0 auto;

        @media (max-width: 1371px) {
            justify-content: flex-start;
            align-items: center;
        }

        @media (max-width: 992px) {
            .footer--address {
                margin: 0;
            }
        }

        @media (max-width: 576px) {
            width: 100%;
        }

        .footer--address {
            max-width: 575px;
            // min-width: 475px;
            margin: 0 45px 0 0;

            p {
                margin: 0 0 25px
            }
        }

        .footer--link-container {
            display: flex;
            flex-flow: row wrap;

            // min-width: 400px;

            .link-column {
                padding: 0 1.75rem;
                padding-left: 0;

                li {
                    margin-bottom: 0.1rem;
                }

                &.link-2 {
                    margin-top: 31px;
                }
            }
        }
    }

    .footer--lower-content {
        p {
            text-align: center;
            margin: 10px 0 0;

            b {
                color: var(--dark-bg-font);
            }
        }

        .social-media--icons {
            text-align: center;

            svg {
                width: 35px;
                height: 35px;

                fill: var(--dark-bg-font);

                margin: 0 20px 10px 0;

                &:hover {
                    fill: var(--dark-bg-hover-font);
                }
            }
        }
    }
}